import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import SEO from "../components/seo"
import Title from "../components/title"
import styles from "../css/text.module.css"

const Rules = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Regeln" />
      <StyledHero img={data.rulesBcg.childImageSharp.fluid} />
      <section className={styles.text}>
        <Title title="Grundregeln" subtitle="der Plattform" />
        <div className={styles.center}>
          <article>
            <h4>Pro bono und Selbstkostenkompensation</h4>
            <p>
              Grundsätzlich werden alle über die Plattform angebotenen und
              abgerufenen Leistungen nicht-monetär/gratis bis Ende Juli 2020 zur
              Verfügung gestellt. Leistungen ab August sind dann bilateral
              zwischen den Marktteilnehmern zu bewerten und zu vereinbaren. Die
              Plattform wird jedoch nach öffentlichen und privaten Geldern
              suchen, um die erbrachten Leistungen zu bezuschussen und damit die
              Anbieter wirtschaftlich zu stützen. Anbieter, die nicht von
              vornherein einen Kompensationsverzicht erklären, erhalten eine
              leistungsumfanggerechte Ausschüttung aus dem generierten
              Finanzpool. Darüber hinaus ist uns bewusst, dass es derzeit
              Selbständige und Kleinunternehmen besonders hart trifft. Daher
              werden wir nach finanzierten Projekten Ausschau halten, die für
              diese Gruppe leistbar sind und auch abgerechnet werden können.
              VsCovidUnited bietet dazu ein faires und für alle Anbieter
              gleiches Verfahren und stellt dazu volle Transparenz her.
              Garantien von oder finanzielle Ansprüche an die Plattform gibt es
              vorab nicht.
            </p>
            <h4>Bezahlung durch den Private Equity Hilfsfons</h4>
            <p>
              Wir werden außerdem Anstrengungen unternehmen einen private equity
              basierten Investment Fund aufzubauen, dessen Regeln der
              Zielsetzung der Initiative gerecht wird. Hierbei ist vorgesehen,
              dass der Fond erbrachte Projektleistungen vergütet und in gleichem
              Maße Anteile vom Hilfe empfangenden Unternehmen übertragen
              bekommt. Diese Beteiligung darf dabei eine Minderheitsbeteiligung
              von 19% nicht übersteigen und das Unternehmen kann jederzeit in
              einem Zeitraum von drei Jahren bei verbesserter Liquidität die
              Anteile zurückfordern.
            </p>
            <h4>Virtualität und Remote Services</h4>
            <p>
              Voraussetzung für jedes angebotene und nachgefragte Projekt ist,
              dass es maximal virtuell abgearbeitet werden kann. Wir gehen davon
              aus, dass dies im Professional Service Bereich zu nahezu 100%
              möglich sein muss. VsCovidUnited stellt entsprechende technische
              Tools zur Verfügung.
            </p>
            <h4>Qualifikation</h4>
            <p>
              Jeder Anbieter deklariert die Qualifikation der angebotenen
              Mitarbeiterkapazität, bzw. den Qualitätsstandard der angebotenen
              Dienstleistungen. Die Teilnahme an der Plattform wird nicht
              garantiert, ebenso wenig wie eine Zuteilung auf eine Nachfrage.
            </p>
            <h4>
              Geschwindigkeit und gegenseitige Hilfsbereitschaft gehen vor
              bürokratische und rechtliche Hürden
            </h4>
            <p>
              Wir sind uns darüber bewusst, dass die angestrebten
              Zusammenarbeiten zu kritischen rechtlichen Fragen und letztlich
              auch Fällen z.B. hinsichtlich Haftung, Gewährleistung, etc. führen
              werden. Grundsätzlich gilt, dass alle Beteiligten nach bestem
              Wissen, Gewissen und Bemühen und unter Einhaltung des gesetzlichen
              Rahmens (Antikorruption, DSGVO, etc.) agieren. Dieses ist
              ebenfalls zu deklarieren. VsCovidUnited arbeitet an einem
              entsprechenden Vertragskonstrukt, das der jetzigen Situation und
              dem Grundgedanken der Plattform gerecht wird.
            </p>
            <h4>Ehrliche Absichten und Transparenz</h4>
            <p>
              Um es klar zu sagen - Die Initiative steht im Zeichen der Hilfe.
              Wir werden sehr genau darauf achten, dass die Plattform nicht
              missbraucht wird und bitten gleichzeitig jeden Teilnehmer, uns bei
              diesem Monitoring aktiv zu unterstützen. Die Plattform kann nur
              funktionieren, wenn jeder Marktplatzteilnehmer transparent agiert.
              Dies gilt insbesondere hinsichtlich Qualifikation, Qualität,
              Verfügbarkeit, Zusagen, Leistungsmenge und -umfang.
            </p>
            <h4>Internationalität</h4>
            <p>
              Wir machen vor Grenzen nicht halt. Auch wenn wir uns primär selbst
              helfen und stützen wollen, sind wir solidarisch mit unseren zum
              Teil noch schlimmer betroffenen Nachbarn, wie zum Beispiel in
              Italien oder Österreich. Daher ist die Plattform offen für
              internationale Nachfrager und Anbieter.
            </p>
            <h4>Reputation</h4>
            <p>
              Die Plattform wird von einer intensiven Öffentlichkeitsarbeit
              begleitet, um für jeden Teilnehmer einen Reputationsnutzen zu
              generieren. Hierfür wird die Plattform auch Mechanismen wie
              Zertifikate, Awards und Ähnliches entwickeln.
            </p>
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    rulesBcg: file(relativePath: { eq: "rulesBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Rules
